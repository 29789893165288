import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from '../../components/Layout/Main';
import userCan from '../../config/user_can';
import { formatDate } from '../../api/util';

import PasswordResetModal from '../Layout/modals/passwordReset';
import AssignUserApp from '../Layout/modals/AssignUserApp';
import SweetAlertWrapper from '../../components/Layout/sweetAlert';

import UserContext from "../../context/users/userContext";
// import AlertContext from '../../context/alerts/alertContext';
// import AuthContext from '../../context/auth/authContext';
import ChangeUserStatus from '../Layout/modals/ChangeUserStatus';




const Users = () => {
  const userContext = useContext(UserContext);
  const { fetchUsers, users, notification, clear_notifications } = userContext;

  // const alertContext = useContext(AlertContext);

  // const authContext = useContext(AuthContext);
  // const { get_user_details_from_token, token } = authContext;
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [showChangeUserStatusModal, setShowChangeUserStatusModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10); // Number of users per page
  const [action, setAction] = useState('');

  const [showAssignAppModal, setShowAssignAppModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

  useEffect(() => {
    fetchUsers(search, currentPage, usersPerPage);
  }, [search, currentPage]);

  // Handle notifications
  useEffect(() => {
    if (notification) {
      setAlertMessage({ text: notification.text, type: notification.type });
      setShowAlert(true);
    }
  }, [notification]);

  const handleConfirm = () => {
    setShowAlert(false);
    clear_notifications();
  };

  const handleShow = (userId) => {
    setSelectedUserId(userId);
    setShowModal(true);
  };

  const handleShowAssignApp = (userId) => {
    setSelectedUserId(userId);
    setShowAssignAppModal(true);
  };

  const handleShowChangeUserStatus = (userId, status) => {
    setSelectedUserId(userId);
    setAction(status ? 'deactivate' : 'activate');
    setShowChangeUserStatusModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setShowAssignAppModal(false);
    setShowChangeUserStatusModal(false);
    // setSelectedUserId('');
    // fetchUsers(search, currentPage, usersPerPage);
  };


  const onSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const onEdit = (id) => {
    navigate(`/users/edit/${id}`);
  };


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the total number of pages (you might need to adjust this based on your data fetching logic)
  const totalPages = Math.ceil(users.length / usersPerPage);


  return (
    <div className="niceadmin">
      <Header />
      <Sidebar page="Users" />
      <Main>
        <SweetAlertWrapper
          show={showAlert}
          title="Notification"
          message={alertMessage.text}
          onConfirm={handleConfirm}
          confirmBtnText="OK"
          type={alertMessage.type}
        />
        <PasswordResetModal show={showModal} handleClose={handleClose} userId={selectedUserId} />
        <AssignUserApp show={showAssignAppModal} handleClose={handleClose} userId={selectedUserId} />
        <ChangeUserStatus show={showChangeUserStatusModal} handleClose={handleClose} userId={selectedUserId} action={action} />
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Users</h5>
            {/* {userCan('Authentication:RegisterUser')} */}
            <Link className="btn btn-primary" id="add-user" to="/users/create">
              Add user
            </Link>
            <input
              type="text"
              className="form-control"
              id="search"
              onChange={onSearch}
              name="search"
              placeholder="Search"
              value={search}
            />
            <table className="table datatable">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Payroll Number</th>
                  <th>Date Added</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.length > 0 ? (
                  users.slice((currentPage - 1) * usersPerPage, currentPage * usersPerPage).map((user, index) => (
                    <tr key={index}>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.phoneNumber}</td>
                      <td>{user.email}</td>
                      <td>{user.payrollNumber}</td>
                      <td>{formatDate(user.dateAdded)}</td>
                      <td>
                        <span className={`badge rounded-pill px-2 py-1 ${user.isActive ? 'bg-success text-white' : 'bg-danger text-white'}`}>
                          {user.isActive ? 'Active' : 'Inactive'}
                        </span>
                      </td>

                      <td>
                        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                          ...
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                          <li>
                            <a
                              className="dropdown-item d-flex align-items-center"
                              href="#"
                              onClick={() => handleShow(user.userCode)}
                              style={{ cursor: 'pointer' }}
                            >
                              <i className="bi bi-person"></i>
                              <span>Reset Password</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item d-flex align-items-center" href="#" onClick={() =>
                              handleShowChangeUserStatus(user.userCode, user.isActive)
                            }>
                              <i className="bi bi-gear"></i>
                              <span>{user.isActive ? 'Deactivate' : 'Activate'}</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item d-flex align-items-center" href="#" onClick={() => onEdit(user.userCode)}>
                              <i className="bi bi-pencil"></i>
                              <span>Edit</span>
                            </a>
                          </li>

                          <li>
                            <a className="dropdown-item d-flex align-items-center" href="#"
                              onClick={() => handleShowAssignApp(user.userCode)}
                            >
                              <i className="bi bi-pen"></i>
                              <span>Assign Application</span>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No users found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <nav aria-label="Page navigation">
              <ul className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </Main>
    </div>
  );
};

export default Users;
