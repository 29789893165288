import React, { useState, useEffect, useContext } from 'react';
import { Form, Table, Pagination, Dropdown } from 'react-bootstrap';
import AuthContext from '../../context/auth/authContext';

import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Main from '../Layout/Main';
import SweetAlertWrapper from '../Layout/sweetAlert';

import AssignRoleModal from './assignRole';
import UnasignRoleModal from './unAssign';

const ListRoles = () => {

    const authContext = useContext(AuthContext);
    const { roles, getRoles, notification, clear_notifications } = authContext;
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rolesPerPage] = useState(10); // Number of roles per page


    const [showModal, setShowModal] = useState(false);
    const [showUnassignModal, setShowUnassignModal] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');

    const handleClose = () => {
        setShowModal(false);
        setShowUnassignModal(false);
    }

    const handleShow = (roleId) => {
        setSelectedRole(roleId);
        setShowModal(true);
    };

    const handleUnassignShow = (roleId) => {
        setSelectedRole(roleId);
        setShowUnassignModal(true);
    }




    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

    const handleConfirm = () => {
        clear_notifications();
        setShowAlert(false);
    };


    useEffect(() => {
        if (notification) {
          setAlertMessage({ text: notification.text, type: notification.type });
          setShowAlert(true);
        }
    }, [notification]);


    const indexOfLastRole = currentPage * rolesPerPage;
    const indexOfFirstRole = indexOfLastRole - rolesPerPage;
    const currentRoles = roles.slice(indexOfFirstRole, indexOfLastRole);

    const filteredRoles = currentRoles.filter(role =>
        role.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


    useEffect(() => {
        getRoles();
    }
        , []);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to the first page after search
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <Header />
            <Sidebar page="roles" />
            <Main>
                <AssignRoleModal
                    show={showModal}
                    handleClose={handleClose}
                    role={selectedRole}
                />

                <UnasignRoleModal
                    show={showUnassignModal}
                    handleClose={handleClose}
                    role={selectedRole}
                />

                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type}
                />


                <Form.Control
                    type="text"
                    placeholder="Search roles..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="mb-3"
                />

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Role ID</th>
                            <th>Role Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredRoles.length > 0 ? (
                            filteredRoles.map((role) => (
                                <tr key={role.id}>
                                    <td>{role.id}</td>
                                    <td>{role.name}</td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                Actions
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleShow(role)}>
                                                    Assign Role to User
                                                </Dropdown.Item>

                                                <Dropdown.Item onClick={() => handleUnassignShow(role)}>
                                                    Unassign Role from User
                                                </Dropdown.Item>

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2">No roles found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <Pagination className="justify-content-center">
                    <Pagination.Prev
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                    />
                    {[...Array(Math.ceil(roles.length / rolesPerPage))].map((_, i) => (
                        <Pagination.Item
                            key={i + 1}
                            active={i + 1 === currentPage}
                            onClick={() => paginate(i + 1)}
                        >
                            {i + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage === Math.ceil(roles.length / rolesPerPage)}
                    />
                </Pagination>
            </Main>
        </div>
    );
};

export default ListRoles;
