import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom"; // or whatever routing library you're using
import Header from "../../components/Layout/Header";
import { useNavigate } from "react-router-dom";
import Main from "../../components/Layout/Main";
import Sidebar from "../../components/Layout/Sidebar";

import SweetAlertWrapper from "../../components/Layout/sweetAlert";


import CustomersContext from "../../context/otogas/customers/customersContext";


const AddCustomers = () => {


    const navigate = useNavigate();

    const customerContext = useContext(CustomersContext);
    const { addCustomer, updateCustomer, selected_customer, notification, clear_notification } = customerContext

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });


    const handleConfirm = () => {
        setShowAlert(false);
        navigate('/otogas/customers');
        clear_notification();
    };

    const page = "Add Customer";

    const [customer, setCustomer] = useState({
        "customerName": "",
        "customerPhone": "",
        "customerEmail": "",
        "krapin": "",
        "identificationNumber": ""
    });
    console.log(customer)

    const onChange = (e) => {
        const { name, value } = e.target;
        setCustomer({
            ...customer,
            [name]: value
        });
    }
    useEffect(() => {
        if (notification) {
            setAlertMessage({ text: notification.text, type: notification.type });
            setShowAlert(true);
        }
    }, [notification]);



    const onSubmit = (e) => {
        e.preventDefault();
        if (selected_customer) {
            updateCustomer(customer)
        } else {
            addCustomer(customer)
        }
    }

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page={page} />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type}
                />
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <div className="align-center">
                                            <h5 className="card-title">Add Customer</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                                <form onSubmit={onSubmit}>
                                    <div className="form-group">
                                        <label for="customerName">Customer Name</label>
                                        <input type="text" className="form-control" id="customerName" name="customerName" value={customer.customerName} onChange={onChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="customerPhone">Customer Phone</label>
                                        <input type="text" className="form-control" id="customerPhone" name="customerPhone" value={customer.customerPhone} onChange={onChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="customerEmail">Customer Email</label>
                                        <input type="text" className="form-control" id="customerEmail" name="customerEmail" value={customer.customerEmail} onChange={onChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="krapin">KRA Pin</label>
                                        <input type="text" className="form-control" id="krapin" name="krapin" value={customer.krapin} onChange={onChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="identificationNumber">Identification Number</label>
                                        <input type="text" className="form-control" id="identificationNumber" name="identificationNumber" value={customer.identificationNumber} onChange={onChange} />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </Main>
        </div>
    )
}
export default AddCustomers;