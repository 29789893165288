import React from 'react';
import { Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Layout/Header';
import Sidebar from '../components/Layout/Sidebar';
import Main from '../components/Layout/Main';

const UnauthorizedPage = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate('/');
    };

    return (
        <React.Fragment>
            <Header />
            <Sidebar />
            <Main>
            <div className="niceadmin">
                <main>
                    <div className="container">
                        <Col>
                            <h1 className="display-1">403</h1>
                            <h2>Unauthorized Access</h2>
                            <p className="lead">
                                Sorry, you don’t have permission to view this page.
                            </p>
                            <Button variant="primary" onClick={handleGoBack}>
                                Go to Home
                            </Button>
                        </Col>
                    </div>
                </main>
            </div>
            </Main>
        </React.Fragment>
    );
};

export default UnauthorizedPage;
