import React, { useEffect, useContext, useState } from "react";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import StationsContext from "../../context/otogas/stations/stationsContext";
import CustomersContext from "../../context/otogas/customers/customersContext";
import { Modal, Button, Form } from "react-bootstrap";
import AddSaleModal from "../sales/addSale";
import SweetAlertWrapper from '../../components/Layout/sweetAlert';
import { formatDate } from "date-fns";

const Shiftvariances = () => {
    const stationsContext = useContext(StationsContext);
    const customersContext = useContext(CustomersContext);
    const { shift_variances, listShiftvariances } = stationsContext;
    const { clearVariance, notification, clear_notification } = customersContext;

    const [showDeferModal, setShowDeferModal] = useState(false);
    const [showWriteOffModal, setShowWriteOffModal] = useState(false);
    const [showAddSaleModal, setShowAddSaleModal] = useState(false);
    const [selectedVariance, setSelectedVariance] = useState(null);
    const [deferReason, setDeferReason] = useState("");

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

    const variances = [
        {
            id: 1,
            shiftNumber: "1",
            stationName: "Station A",
            dispenserName: "Dispenser A",
            dispenserCode: "D01",
            nozzleCode: "N001",
            nozzleName: "Nozzle A",
            name: "Attendant A",
            dateCreated: "2021-08-01",
            openingReading: 100,
            closingReading: 200,
            expectedClosingReading: 150,
            quantitySold: 50,
            variance: 0,
            stationCode: "S004",
            userCode: "00004",


        }
    ]


    const handleDefer = (shiftNumber) => {
        setSelectedVariance(shiftNumber);
        setShowDeferModal(true);
    };

    const handleWriteOff = (shiftNumber) => {
        setSelectedVariance(shiftNumber);
        setShowWriteOffModal(true);
    };

    useEffect(() => {
        if (notification) {
            console.log(notification);

            setAlertMessage({ text: notification.text, type: notification.type });
            setShowAlert(true);
        }
    }, [notification]);
    const handleConfirm = () => {
        setShowAlert(false);
        clear_notification();
    };

    const handleConfirmDefer = async () => {
        if (deferReason) {
            await clearVariance("defer", selectedVariance, deferReason);
            setShowDeferModal(false);
            setDeferReason("");
        } else {
            alert("Please provide a reason to defer.");
        }
    };

    const handleConfirmWriteOff = async () => {
        await clearVariance("write_off", selectedVariance);
        setShowWriteOffModal(false);
    };

    const handleShowAddSaleModal = (variance) => {
        setSelectedVariance(variance);
        setShowAddSaleModal(true);
    };

    const handleCloseAddSaleModal = () => {
        setShowAddSaleModal(false);
        setSelectedVariance(null);
    };

    useEffect(() => {
        listShiftvariances();
    }, [listShiftvariances]);

    const page = "Shift Variances";

    return (
        <>
            <Header />
            <Sidebar />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type}
                />
                <h2>{page}</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Shift Number</th>
                            <th>Station Name</th>
                            <th>Dispenser Name</th>
                            <th>Nozzle Name</th>
                            <th>Attendant</th>
                            <th>Date Created </th>
                            <th>Opening</th>
                            <th>Closing</th>
                            <th>Expected Closing</th>
                            <th>Sold Quantity</th>
                            <th>Variance</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {shift_variances.map((shiftvariance) => (
                            <tr key={shiftvariance.id}>
                                <td>{shiftvariance.shiftNumber}</td>
                                <td>{shiftvariance.stationName}</td>
                                <td>{shiftvariance.dispenserName}</td>
                                <td>{shiftvariance.nozzleName}</td>
                                <td>{shiftvariance.name}</td>
                                <td>{formatDate(new Date(shiftvariance.dateCreated), "yyyy-MM-dd")}</td>
                                <td>{shiftvariance.openingReading}</td>
                                <td>{shiftvariance.closingReading}</td>
                                <td>{shiftvariance.expectedClosingReading}</td>
                                <td>{shiftvariance.quantitySold}</td>
                                <td>{shiftvariance.variance}</td>
                                <td>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-secondary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Actions
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <li>
                                                <button
                                                    className="dropdown-item"
                                                    onClick={() => handleDefer(shiftvariance.shiftNumber)}
                                                >
                                                    Defer Variance
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className="dropdown-item"
                                                    onClick={() => handleWriteOff(shiftvariance.shiftNumber)}
                                                >
                                                    Write Off Variance
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className="dropdown-item"
                                                    onClick={() => handleShowAddSaleModal(shiftvariance)}
                                                >
                                                    Add Sale
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Defer Modal */}
                <Modal show={showDeferModal} onHide={() => setShowDeferModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Defer Variance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Reason for Deferring</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter reason"
                                value={deferReason}
                                onChange={(e) => setDeferReason(e.target.value)}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowDeferModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleConfirmDefer}>
                            Confirm Defer
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Write Off Modal */}
                <Modal show={showWriteOffModal} onHide={() => setShowWriteOffModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Write Off Variance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to write off the variance for Shift Number {selectedVariance}?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowWriteOffModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleConfirmWriteOff}>
                            Confirm Write Off
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Add Sale Modal */}
                <AddSaleModal
                    show={showAddSaleModal}
                    handleClose={handleCloseAddSaleModal}
                    selectedVariance={selectedVariance}
                />
            </Main>
        </>
    );
};

export default Shiftvariances;
