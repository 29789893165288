// Importing Libraries
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import userCan from '../../config/user_can';
import AuthContext from "../../context/auth/authContext";


function Sidebar({ page }) {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const [roles, setRoles] = useState([]);

  let urlEmpty = '';
  // Function Logout

  return (
    <React.Fragment>
      <div className="niceadmin">
        <aside id="sidebar" className="sidebar">

          <ul className="sidebar-nav" id="sidebar-nav">

            <li className="nav-item">
              <Link className="nav-link " to="/">
                <i className="bi bi-grid"></i>
                <span>Dashboard</span>
              </Link>
            </li> {/* End Dashboard Nav */}



            <li className="nav-item">
              <a className="nav-link collapsed" data-bs-target="#customer-management-nav" data-bs-toggle="collapse" href="#">
                <i className="bi bi-person"></i><span>Customers</span><i className="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul id="customer-management-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                <li>
                  <a href="/otogas/customers">
                    <i className="bi bi-circle-fill"></i><span> Customers</span>
                  </a>
                </li>
                <li
                >
                  <a href="/otogas/customers/balances">
                    <i className="bi bi-dollar-fill"></i><span>Customer balances</span>
                  </a>
                </li>

                <li>
                  <a href="/otogas/vehicles">
                    <i className="bi bi-car-fill"></i><span>Vehicles</span>
                  </a>
                </li>

              </ul>
            </li> {/* End Tables Nav */}





            {/* <li className="nav-item">
      <Link className="nav-link " to="/otogas/users">
      <i className="bi bi-person-circle"></i>
        <span>User Management</span>
      </Link>
    </li> End Dashboard Nav */}






            <li className="nav-item">
              <Link className="nav-link " to="/otogas/stations">
                <i className="bi bi-gear"></i>
                <span>Stations</span>
              </Link>
            </li>
            {/* {/* End Dashboard Nav */}


            <li className="nav-item">
              <a className="nav-link collapsed" data-bs-target="#sales-nav" data-bs-toggle="collapse" href="#">
                <i className="bi bi-layout-text-window-reverse"></i><span>Sales</span><i className="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul id="sales-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                <li>
                  <a href="/otogas/sales">
                    <i className="bi bi-circle-fill"></i><span> Transactions</span>
                  </a>
                </li>
                {/* <li>
                  <a href="/users/roles">
                    <i className="bi bi-circle-fill"></i><span>Roles</span>
                  </a>
                </li> */}

                <li>
                  <a href="/otogas/variances">
                    <i className="bi bi-circle-fill"></i><span>Variances</span>
                  </a>
                </li>

              </ul>
            </li>


            <li className="nav-item">
              <a className="nav-link collapsed" data-bs-target="#payments-nav" data-bs-toggle="collapse" href="#">
                <i className="bi bi-layout-text-window-reverse"></i><span>Finance</span><i className="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul id="payments-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                
                <li>
                  <a href="/otogas/tills">
                    <i className="bi bi-coin"></i><span>Tills</span>
                  </a>
                </li> {/* End Dashboard Nav */}

                {/* <li>
                  <a href="/users/roles">
                    <i className="bi bi-circle-fill"></i><span>Roles</span>
                  </a>
                </li> */}

                <li>
                  <a href="/otogas/mpesa-payments">
                    <i className="bi bi-circle-fill"></i><span>Mpesa Payments</span>
                  </a>
                </li>

              </ul>
            </li>




            <li className="nav-item">
              <a className="nav-link collapsed" data-bs-target="#staff-management-nav" data-bs-toggle="collapse" href="#">
                <i className="bi bi-layout-text-window-reverse"></i><span>Staff Management</span><i className="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul id="staff-management-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                {
                  userCan(user, "Get:Authentication:GetAllUsers") && (
                    <li>
                      <a href="/otogas/users">
                        <i className="bi bi-circle-fill"></i><span> Users</span>
                      </a>
                    </li>
                  )
                }
                {/* <li>
                  <a href="/users">
                    <i className="bi bi-circle-fill"></i><span> Users</span>
                  </a>
                </li> */}
                <li>
                  <a href="/users/roles">
                    <i className="bi bi-circle-fill"></i><span>Roles</span>
                  </a>
                </li>

                <li>
                  <a href="/users/profile">
                    <i className="bi bi-circle-fill"></i><span>Profile</span>
                  </a>
                </li>

              </ul>
            </li> {/* End Tables Nav */}

            <li className="nav-item">
              <Link className="nav-link " to="/otogas/settings">
                <i className="bi bi-gear"></i>
                <span>Settings</span>
              </Link>
            </li>
            {/* End Dashboard Nav */}


            <li className="nav-item">
              <a className="nav-link collapsed" data-bs-target="#reports-nav" data-bs-toggle="collapse" href="#">
                <i className="bi bi-layout-text-window-reverse"></i><span>Reports</span><i className="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul id="reports-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">

                <li>
                  <a href="#">
                    <i className="bi bi-circle-fill"></i><span>Reports</span>
                  </a>
                </li>
              </ul>
            </li> {/* End Tables Nav */}

            <li className="nav-item">
              <a className="nav-link collapsed" data-bs-target="#deliveries-nav" data-bs-toggle="collapse" href="#">
                <i className="bi bi-layout-text-window-reverse"></i><span>Bulk Deliveries</span><i className="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul id="deliveries-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">

                <li>
                  <a href="/otogas/bulk-orders">
                    <i className="bi bi-circle-fill"></i><span>Bulk Deliveries</span>
                  </a>
                </li>
                <li>
                  <a href="/otogas/bulk-orders/create">
                    <i className="bi bi-circle-fill"></i><span>Create Order</span>
                  </a>
                </li>
              </ul>
            </li> {/* End Tables Nav */}




          </ul>

        </aside> {/* End Sidebar*/}
      </div>
    </React.Fragment>
  );
}

export default Sidebar;