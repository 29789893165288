import React, { useContext } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CustomerContext from "../../context/otogas/customers/customersContext";

const VehicleTopupModal = ({ show, handleClose, vehicle }) => {
  const customerContext = useContext(CustomerContext);
  const { topUpCustomerBalance } = customerContext;

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      vehicleCode: vehicle.vehicleRegistrationNumber, // Pre-fill the vehicle code with vehicleRegistrationNumber
    },
  });

  console.log(vehicle);

  const onSubmit = (data) => {
    // Use window.confirm for a simple confirmation dialog
    const isConfirmed = window.confirm(`Are you sure you want to top up the vehicle with registration number ${vehicle.vehicleRegistrationNumber}?`);
    
    if (isConfirmed) {
      console.log(data);
      topUpCustomerBalance({
        ...data,
        vehicleCode: vehicle.vehicleCode,
      }); // Perform the top-up
      handleClose(); // Close the modal after submitting
   
    } else {
      alert("Vehicle top-up cancelled."); // Show a cancellation alert
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Vehicle Top-up</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {/* Vehicle Code (Non-editable) */}
          <Form.Group controlId="vehicleCode">
            <Form.Label>Vehicle</Form.Label>
            <Form.Control
              type="text"
              value={vehicle.vehicleRegistrationNumber}
              readOnly
              {...register("vehicleCode")}
            />
          </Form.Group>

          {/* Transaction Reference */}
          <Form.Group controlId="transactionReference" className="mt-3">
            <Form.Label>Transaction Reference</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter transaction reference"
              {...register("transactionReference", {
                required: "Transaction reference is required",
              })}
              isInvalid={errors.transactionReference}
            />
            {errors.transactionReference && (
              <Form.Control.Feedback type="invalid">
                {errors.transactionReference.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          {/* Amount */}
          <Form.Group controlId="amount" className="mt-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount"
              {...register("amount", { required: "Amount is required", min: 1 })}
              isInvalid={errors.amount}
            />
            {errors.amount && (
              <Form.Control.Feedback type="invalid">
                {errors.amount.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          {/* Submit Button */}
          <Button variant="primary" type="submit" className="mt-4">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default VehicleTopupModal;
