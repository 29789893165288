import React, { useState, useContext } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import CustomersContext from "../../context/otogas/customers/customersContext";
import SweetAlert from 'react-bootstrap-sweetalert'; // Remove this if not needed anymore

const RemoveSale = ({ show, handleClose, saleId }) => {
    const customerContext = useContext(CustomersContext);
    const { removeSale } = customerContext;

    const [reason, setReason] = useState("");
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    // This handles the initial remove button click and opens confirmation modal
    const handleRemoveClick = () => {
        if (reason.trim() === "") {
            alert("Please provide a reason for removing the sale.");
            return;
        }
        setShowConfirmModal(true);
    };

    // This function will handle the actual removal after confirmation
    const handleConfirm = () => {
        const saleData = {
            saleId,
            reason,
        };

        console.log(saleData);
        removeSale(saleId); // Call to remove the sale
        setShowConfirmModal(false); // Close the confirmation modal
        handleClose(); // Close the initial modal
    };

    return (
        <>
            {/* Initial Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Sale</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formTransactionCode">
                            <Form.Label>Sale Id</Form.Label>
                            <Form.Control
                                type="text"
                                name="saleId"
                                value={saleId}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group controlId="formReason">
                            <Form.Label>Reason for Removal</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter reason for removing this sale"
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleRemoveClick}>
                        Remove Sale
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Confirmation Modal */}
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Sale Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove this sale?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleConfirm}>
                        Yes, Remove Sale
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RemoveSale;
