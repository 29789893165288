import axios from './index';

// lists
export const list_customers = async (page, page_size, customerName, customerPhone) => {
    // otogas/Customer/GetAllCustomers/1/100?customerName=100&customerPhone=099
    try {
        const res = await axios.get(`/otogas/Customer/GetAllCustomers/${page}/${page_size}?customerName=${customerName}&customerPhone=${customerPhone}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_products = async () => {
    try {
        const res = await axios.get('/otogas/Setup/GetProducts');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_stations = async () => {
    try {
        const res = await axios.get('/otogas/Station/GetAllStations');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// list vehicles
export const list_vehicles = async (page_size, page, customerName, vehicleRegistrationNumber, productCode, status) => {
    console.log(page, page_size, customerName, vehicleRegistrationNumber, productCode, status)
    try {

        // otogas/Customer/GetAllVehicles/10/1?customerName=name&vehicleRegistrationNumber=name&productCode=1&status=true'
        const res = await axios.get(`/otogas/Customer/GetAllVehicles/${page}/${page_size}?customerName=${customerName}&vehicleRegistrationNumber=${vehicleRegistrationNumber}&productCode=${productCode}&status=${status}`);
        console.log(res, "res")
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}



// tills
export const list_tills = async () => {
    try {
        const res = await axios.get('/payments/Payments/GetAllTills');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const add_till = async (formData) => {
    try {
        const res = await axios.post('/payments/Payments/AddTill', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const update_till = async (formData) => {
    try {
        const res = await axios.post('/payments/Payments/UpdateTill', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const assign_till_to_dispenser = async (formData) => {
    try {
        const res = await axios.post('/payments/Payments/AssignTillToDispenser', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const change_till_status = async (tillId, status) => {
    try {
        const res = await axios.post(`/otogas/UpdateTillStatus?tillId=${tillId}&IsActive=${status}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// customer

export const create_customer = async (formData) => {
    try {
        const res = await axios.post('/otogas/Customer/AddCustomer', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const update_customer_status = async (customer_id, status) => {
    try {
        const res = await axios.post(`/otogas/UpdateCustomerStatus?customerId=${customer_id}&IsActive=${status}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const update_customer_info = async (formData) => {
    try {
        const res = await axios.post('/otogas/UpdateCustomerInfo', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const search_customer = async (search_customer) => {
    // https://os.protoenergy.com/otogas/Customer/SearchVehicle?VehicleRegNo=KBR%20450L&StationCode=0001' \
    try {
        const res = await axios.post(`/otogas/Customer/SearchVehicle?VehicleRegNo=${search_customer.regno}&StationCode=${search_customer.station}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// vehicle
export const add_vehicle = async (formData) => {
    try {
        const res = await axios.post('/otogas/Customer/AddVehicle', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const update_vehicle = async (formData) => {
    try {
        const res = await axios.patch('/otogas/Customer/UpdateVehicle', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const update_vehicle_status = async (vehicleCode, status) => {
    //    otogas/Customer/DeactivateVehicle?vehicleCode=00'
    // otogas/Customer/ActivateVehicle?vehicleCode=2340' 

    if (status === 'activate') {
        try {
            const res = await axios.post(`/otogas/Customer/ActivateVehicle?vehicleCode=${vehicleCode}`);
            return res.data;
        }
        catch (error) {
            return error.response.data;
        }
    }
    else {
        try {
            const res = await axios.post(`/otogas/Customer/DeactivateVehicle?vehicleCode=${vehicleCode}`);
            return res.data;
        }
        catch (error) {
            return error.response.data;
        }

    }
}


export const uninstall_vehicle = async (vehicleCode) => {
    console.log(vehicleCode, "vehicleCode")
    try {
        const res = await axios.post(`/otogas/Customer/MarkVehicleAsUnInstalled?vehicleCode=${vehicleCode}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}
export const search_vehicle_by_reg_no = async (reg_no) => {
    try {
        const res = await axios.post(`/otogas/SearchVehicleByRegNumber?vehicleRegistrationNumner=${reg_no}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const transfer_vehicle = async (formData) => {
    console.log(formData, "formdata")
    try {
        const res = await axios.post('/otogas/Customer/TransferVehicle', formData);
        console.log(res, "res")
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}


// orders

export const create_order = async (formData) => {
    try {
        const res = await axios.post('/otogas/AddOrder', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const add_plan = async (formData) => {
    try {
        const res = await axios.post('/otogas/AddPlan', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const cancel_delivery_plan = async (deliveryPlanId) => {
    try {
        const res = await axios.post(`/otogas/CancelDeliveryPlan`, { deliveryPlanId });
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const plan_assignment = async (formData) => {
    try {
        const res = await axios.put('/otogas/PlanAssignments', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const attach_order = async (deliveryPlanId, orderIds) => {
    try {
        const res = await axios.post("/otogas/AttachOrder",
            {
                deliveryPlanId,
                orderIds
            });
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_orders = async () => {
    try {
        const res = await axios.get('/bulk/Bulk/BulkOrders');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// loading
export const new_loading = async (formData) => {
    try {
        const res = await axios.post('/otogas/Loading', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const offloading = async (formData) => {
    try {
        const res = await axios.post('/otogas/Offloading', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const deliverorder = async (formData) => {
    try {
        const res = await axios.post('/otogas/DeliverOrder', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}


export const remove_order = async (orderId) => {
    try {
        // stringify the order id

        const res = await axios.delete("/otogas/RemoveOrder",
            [
                JSON.stringify(orderId)
            ])

        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}


// approvals
export const finance_approval = async (formData) => {
    try {
        const res = await axios.post('/otogas/FinanceApproval', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// manager approval
export const manager_approval = async (formData) => {
    try {
        const res = await axios.post('/otogas/ManagerApproval', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// stations

export const add_stations = async (formData) => {
    try {
        const res = await axios.post('/otogas/Station/AddStation', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const update_station = async (formData) => {
    try {
        const res = await axios.post('/otogas/UpdateStation', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const update_station_status = async (stationCode, status) => {
    try {
        const res = await axios.post(`/otogas/UpdateStationStatus?stationCode=${stationCode}&isActive=${status}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const add_station_tank = async (formData) => {
    try {
        const res = await axios.post('/otogas/Station/AddTank', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_station_tanks = async (stationCode) => {
    try {
        const res = await axios.get(`/otogas/Station/StationTank?stationCode=${stationCode}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const update_station_tank = async (formData) => {
    try {
        const res = await axios.post('/otogas/UpdateStationTank', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_tanks = async () => {
    try {
        const res = await axios.get('/otogas/Station/GetAllTanks');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// export const list_tanks = async (station) => {
//     try {
//         const res = await axios.get(`/otogas/StationTanks?stationCode=${station}`);
//         return res.data;
//     }
//     catch (error) {
//         return error.response.data;
//     }
// }

export const search_station = async (stationCode) => {
    try {
        const res = await axios.get(`/otogas/SearchStation?stationCode=${stationCode}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// station nozzles
export const add_nozzle = async (formData) => {
    try {
        const res = await axios.post('/otogas/Station/AddNozzle', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const update_nozzle = async (formData) => {
    try {
        const res = await axios.post('/otogas/UpdateStationNozzle', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_nozzles = async (station) => {
    try {
        const res = await axios.get(`/otogas/Station/GetAllNozzles`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// station dispensers
export const add_station_dispenser = async (formData) => {
    try {
        const res = await axios.post('/otogas/Station/AddDispenser', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const update_station_dispenser = async (formData) => {
    try {
        const res = await axios.post('/otogas/UpdateStationDispenser', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_dispensers = async () => {
    try {
        const res = await axios.get(`/otogas/Station/GetAllDispensers`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_station_dispensers = async (stationCode) => {
    try {
        const res = await axios.get(`/otogas/Station/ListStationDispensers?stationCode=${stationCode}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// list dispenser nozzles
export const list_dispenser_nozzles = async (dispenserCode) => {
    try {
        const res = await axios.get(`/otogas/Station/ListDispenserNozzles?dispenserCode=${dispenserCode}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const assign_user_to_dispenser = async (formData) => {
    console.log(formData, "formdata")
    try {
        const res = await axios.post('/otogas/Station/AssignDispenser', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}


//  sales
export const add_sale = async (formData) => {
    try {
        const res = await axios.post('/otogas/Sales/AddMisingSale', formData);
        console.log(res, "res")
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const transfer_sale_to_another_nozzle = async (transactionCode, nozzleCode) => {
    // otogas/Sales/TransferSaleToAnotherNozzle?transactionCode=009&nozzleCode=3445'
    try {
        const res = await axios.post(`/otogas/Sales/TransferSaleToAnotherNozzle?transactionCode=${transactionCode}&nozzleCode=${nozzleCode}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const reverse_a_sale = async (transactionCode) => {
    try {
        const res = await axios.post(`/otogas/Sales/ReverseASale?transactionCode=${transactionCode}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const remove_sale = async (saleId) => {
    try {
        const res = await axios.post(`/otogas/Sales/ReverseasaleAsync?saleid=${saleId}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const clear_variance = async (type, shiftNumber) => {
    if (type === 'defer') {
        try {
            const res = await axios.post(`/otogas/Sales/DeferVariance?shiftNumber=${shiftNumber}`);
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    } else if (type === 'write_off') {
        try {
            const res = await axios.post(`/otogas/Sales/WriteOffVariance?shiftNumber=${shiftNumber}`);
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    }
};

export const stoke_take = async (formData) => {
    try {
        const res = await axios.post('/otogas/Stock/StokeTake', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// variance

// otogas/Stock/Shiftvariances
export const shift_variance = async () => {
    try {
        const res = await axios.get('/otogas/Stock/AllVariances');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// list sales
export const list_sales = async (shiftNumber, dispenserName, nozzleName, paymentTypeName, startDate, endDate, pageNumber, pageSize) => {
    try {
        // otogas/Sales/AllSales?shiftNumber=12&dispenserName=23&nozzleName=23&paymentTypeName=23&startDate=32&endDate=32&pageNumber=1&pageSize=10' \
        const res = await axios.get(`/otogas/Sales/AllSales?shiftNumber=${shiftNumber}&dispenserName=${dispenserName}&nozzleName=${nozzleName}&paymentTypeName=${paymentTypeName}&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_payment_types = async () => {
    try {
        const res = await axios.get('/otogas/Sales/AllPaymentTypes');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_app_payment_types = async () => {
    try {
        const res = await axios.get('/otogas/Sales/MobileAppPaymentTypes');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const add_payment_type = async (formData) => {
    try {
        const res = await axios.post('/otogas/Setup/AddPaymentType', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_customer_wallets = async () => {
    try {
        const res = await axios.post('/otogas/Sales/GetAllCustomerBalances');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const top_up_customer_balance = async (formData) => {
    console.log(formData, "formdata ++++")
    try {
        const res = await axios.post('/otogas/Sales/TopUpCustomerWallet', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const set_global_pricing = async (productCode, newPrice) => {
    // otogas/Setup/GlobalPriceChange?productCode=02&newPrice=1000' 
    try {
        const res = await axios.get(`/otogas/Setup/GlobalPriceChange?productCode=${productCode}&newPrice=${newPrice}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const change_station_price = async (formData) => {
    try {
        const res = await axios.post('/otogas/Setup/ChangePrice', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const add_product = async (product) => {
    try {
        const res = await axios.post('/otogas/Setup/AddProduct', product);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const get_vehicle_summary = async (vehicleCode, startDate, endDate) => {
    //    otogas/Sales/GetCustomerStatement/KBZ097R/2024-01-01/2024-12-12'
    try {
        const res = await axios.post(`/otogas/Sales/GetCustomerStatement/${vehicleCode}/${startDate}/2024-09-17`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const get_dashboard_data = async () => {
    try {
        const res = await axios.get('/otogas/Sales/GetDashBoardData');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}


export const set_initial_stock = async (formData) => {
    try {
        console.log(formData, "formData")
        const res = await axios.post('/otogas/Stock/Initialstocktake', {
            readings: [
                formData
            ]
        });
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const searchVehicle = async (stationCode, vehicleRegNo) => {

    try {
        const res = await axios.get(`/otogas/Customer/SearchVehicle/${stationCode}/${vehicleRegNo}`);
        console.log(res, "res")
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
};

export const searchEmployee = async (userCode) => {
    console.log(userCode, "usercode")
    try {
        const response = await axios.get(`/otogas/Sales/GetEmployeePrice?userCode=${userCode}`);
        console.log(response, "response")
        return response.data;
    } catch (error) {
        console.error("Error during employee search:", error);
        return error.response?.data || { error: "An error occurred while searching the employee" };
    }
};



export const validatePaymentReference = async (dispenserCode, transId) => {
    // payments/Payments/ConfirmPayment/SIF2O6HUMS/D001
    try {
        const response = await axios.post(`/payments/Payments/ConfirmPayment/${transId}/${dispenserCode}`);
        console.log(response, "response 09000")
        return response.data;
    } catch (error) {
        console.error("Error during payment validation:", error);
        return error.response?.data || { error: "An error occurred while validating the payment" };
    }
};

export const list_station_assignments = async (stationCode) => {
    console.log(stationCode, "stationCode")
    try {
        const res = await axios.get(`/otogas/Station/GetAllDispenserAssignments${stationCode}`);
        console.log(res, "res")
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_tank_sizes = async () => {
    try {
        const res = await axios.get('/otogas/Customer/GetTankSizes');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}










