import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from '../../components/Layout/Main';
import { Link } from 'react-router-dom';
import CustomerContext from '../../context/otogas/customers/customersContext';
import SweetAlertWrapper from '../../components/Layout/sweetAlert';
import VehicleTopupModal from './topupCustomer';
import UpdateVehicleModal from './updateVehicle';
import ChangeVehicleStatusModal from './changeStatus';
import TransferVehicleModal from './transferVehicle';
import { format } from 'date-fns';
import { Modal, Button } from 'react-bootstrap';
import Pagination from '../../components/Layout/Pagination'; // Importing the Pagination component
import { se } from 'date-fns/locale';

const ListVehicles = () => {
  const customerContext = useContext(CustomerContext);
  const {
    vehicles,
    vehicleTotalRecords,  // Total number of vehicles from the API
    uninstallVehicle,
    fetchVehicles,
    searchVehicle,
    notification,
    clear_notification,
  } = customerContext;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10); // Define the number of vehicles to display per page
  const [search, setSearch] = useState('');
  const [itemsPerPage] = useState(15);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

  // Modal management
  const [showTopupModal, setShowTopupModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [showUninstallModal, setShowUninstallModal] = useState(false);

  const [selectedRegNo, setSelectedRegNo] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedVehicleCode, setSelectedVehicleCode] = useState('');
  const [selectedVehicleStatus, setSelectedVehicleStatus] = useState('');
  const [vehicleToUninstall, setVehicleToUninstall] = useState(null);

  // Fetch vehicles based on pagination, search, and status filter
  useEffect(() => {

    fetchVehicles(
      currentPage,          // Current page
      itemsPerPage,         // Items per page
      search || '',        // customerName
      '',           // Optional vehicleRegistrationNumber
      '',                  // Optional productCode
      selectedStatus || true // Status filter
    );
  }, [currentPage, search, selectedStatus]);

  const totalPages = Math.ceil(vehicleTotalRecords / itemsPerPage);

  // Display notification and alert
  useEffect(() => {
    if (notification) {
      setAlertMessage({ text: notification.text, type: notification.type });
      setShowAlert(true);
    }
  }, [notification]);

  // Handle search input
  const onSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to the first page when a new search is performed
  };

  // Handle status filter change
  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setCurrentPage(1); // Reset to the first page when a new filter is applied
  };

  // Calculate total pages
  // const totalPages = Math.ceil(vehicleTotalRecords / pageSize);

  // Handle closing of modals and resetting state
  const handleCloseTopupModal = () => {
    setShowTopupModal(false);
    setSelectedRegNo('');
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setSelectedVehicle(null);
  };

  const handleCloseChangeStatusModal = () => {
    setShowChangeStatusModal(false);
    setSelectedVehicleCode('');
    setSelectedVehicleStatus('');
  };

  const handleConfirmAlert = () => {
    setShowAlert(false);
    clear_notification();
    fetchVehicles(currentPage, itemsPerPage, search, '', '', selectedStatus);
  };

  const handleCloseUninstallModal = () => {
    setShowUninstallModal(false);
    setVehicleToUninstall(null);
  };

  const handleUninstallVehicle = () => {
    if (vehicleToUninstall) {
      uninstallVehicle(vehicleToUninstall.vehicleCode);
      handleCloseUninstallModal();
    }
  };

  const [showTransferModal, setShowTransferModal] = useState(false);

  const handleCloseTransferModal = () => {
    setShowTransferModal(false);
    setSelectedVehicle(null);
  };

  return (
    <div className="niceadmin">
      <Header />
      <Sidebar page="List Vehicles" />
      <Main>
        <SweetAlertWrapper
          show={showAlert}
          title="Notification"
          message={alertMessage.text}
          onConfirm={handleConfirmAlert}
          confirmBtnText="OK"
          type={alertMessage.type}
        />

        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="card-title">List Vehicles</h5>
              <Link className="btn btn-primary" id="add-vehicle" to="/otogas/vehicles/add">
                Add Vehicle
              </Link>
            </div>

            {/* Topup Modal */}
            {showTopupModal &&
              <VehicleTopupModal
                show={showTopupModal}
                handleClose={handleCloseTopupModal}
                vehicle={selectedVehicle}
              // regNo={selectedRegNo}
              />
            }

            {/* Update Modal */}
            <UpdateVehicleModal
              show={showUpdateModal}
              handleClose={handleCloseUpdateModal}
              selected_vehicle={selectedVehicle}
            />

            {/* Change Status Modal */}
            <ChangeVehicleStatusModal
              show={showChangeStatusModal}
              handleClose={handleCloseChangeStatusModal}
              vehicleCode={selectedVehicleCode}
              status={selectedVehicleStatus}
            />

            {/* Transfer Vehicle Modal */}
            <TransferVehicleModal
              show={showTransferModal}
              handleClose={handleCloseTransferModal}
              vehicle={selectedVehicle}
            />

            {/* Uninstall Confirmation Modal */}
            <Modal show={showUninstallModal} onHide={handleCloseUninstallModal}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Uninstall</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to uninstall the vehicle with Reg No: {vehicleToUninstall?.vehicleRegistrationNumber}?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseUninstallModal}>
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    setSelectedVehicle(vehicleToUninstall);
                    handleUninstallVehicle();
                  }}
                >
                  Uninstall Vehicle
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Search and Status Filter */}
            <div className="d-flex justify-content-between mb-3">
              {/* Search Input */}
              <input
                type="text"
                className="form-control me-2"
                placeholder="Search vehicles"
                value={search}
                onChange={onSearch}
              />

              {/* Status Filter */}
              <select className="form-select" value={selectedStatus} onChange={handleStatusChange}>
                <option value="">All Statuses</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>

            {/* Vehicles Table */}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col"> # </th>
                  <th scope="col">Reg No</th>
                  <th scope="col">Model</th>
                  <th scope="col">Make</th>
                  <th scope="col">Conversion Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Tank Capacity</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Customer Phone</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {vehicles && vehicles.length > 0 ? (
                  vehicles.map((vehicle, index) => (
                    <tr key={vehicle.vehicleCode}>
                      <td scope="row">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                      <td>{vehicle.vehicleRegistrationNumber}</td>
                      <td>{vehicle.vehicleModel}</td>
                      <td>{vehicle.vehicleMake}</td>
                      <td>{format(new Date(vehicle.conversionDate), 'yyyy-MM-dd')}</td>
                      <td>
                        <span className={`badge ${vehicle.isActive ? 'bg-success' : 'bg-danger'}`}>
                          {vehicle.isActive ? 'Active' : 'Inactive'}
                        </span>
                      </td>
                      <td>{vehicle.tankCapacity}</td>
                      <td>{vehicle.customerName}</td>
                      <td>{vehicle.customerPhone}</td>
                      <td>
                        <div className="dropdown">
                          <a
                            className="nav-link nav-profile d-flex align-items-center pe-0"
                            href="#"
                            data-bs-toggle="dropdown"
                          >
                            ...
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li>
                              <a
                                className="dropdown-item d-flex align-items-center"
                                href="#"
                                onClick={() => {
                                  setSelectedVehicle(vehicle);
                                  setShowUpdateModal(true);
                                }}
                              >
                                <i className="bi bi-gear"></i>
                                <span>Edit Vehicle</span>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item d-flex align-items-center"
                                href={`/otogas/vehicles/${vehicle.vehicleCode}`}
                              >
                                <i className="bi bi-person"></i>
                                <span>View</span>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item d-flex align-items-center"
                                href="#"
                                onClick={() => {
                                  setShowTopupModal(true);
                                  setSelectedVehicle(vehicle);

                                  // setSelectedRegNo(vehicle.vehicleCode);
                                }}
                              >
                                <i className="bi bi-wallet"></i>
                                <span>Topup</span>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item d-flex align-items-center"
                                href="#"
                                onClick={() => {
                                  setShowUninstallModal(true);
                                  setVehicleToUninstall(vehicle);
                                }}
                              >
                                <i className="bi bi-trash"></i>
                                <span>Uninstall</span>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item d-flex align-items-center"
                                href="#"
                                onClick={() => {
                                  setShowTransferModal(true);
                                  setSelectedVehicle(vehicle);
                                }}
                              >
                                <i className="bi bi-arrow-right"></i>
                                <span>Transfer</span>
                              </a>
                            </li>
                            <li>
                              {vehicle.isActive ? (
                                <a
                                  className="dropdown-item d-flex align-items-center"
                                  href="#"
                                  onClick={() => {
                                    setShowChangeStatusModal(true);
                                    setSelectedVehicleCode(vehicle.vehicleCode);
                                    setSelectedVehicleStatus('deactivate');
                                  }}
                                >
                                  <i className="bi bi-toggle2-off"></i>
                                  <span>Deactivate</span>
                                </a>
                              ) : (
                                <a
                                  className="dropdown-item d-flex align-items-center"
                                  href="#"
                                  onClick={() => {
                                    setShowChangeStatusModal(true);
                                    setSelectedVehicleCode(vehicle.vehicleCode);
                                    setSelectedVehicleStatus('activate');
                                  }}
                                >
                                  <i className="bi bi-toggle2-on"></i>
                                  <span>Activate</span>
                                </a>
                              )}
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No vehicles found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination Component */}
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </Main>
    </div>
  );
};

export default ListVehicles;
