import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from '../../components/Layout/Main';
import { Link, useNavigate } from 'react-router-dom';
import StationsContext from '../../context/otogas/stations/stationsContext';
import Pagination from '../../components/Layout/Pagination';
import SweetAlertWrapper from "../../components/Layout/sweetAlert";
// import AddDispenser from '../dispensers/addDispenser';

const ListStations = () => {
  const stationsContext = useContext(StationsContext);
  const navigate = useNavigate();
  const { listStations, stations, notification, clear_notification } = stationsContext;

  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [stationsPerPage] = useState(10);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

  const [showAddDispenser, setShowAddDispenser] = useState(false);
  const [selectedStation, setSelectedStation] = useState('');

  useEffect(() => {
    listStations();
  }, []);

  useEffect(() => {
    if (notification) {
      setAlertMessage({ text: notification.text, type: notification.type });
      setShowAlert(true);
    }
  }, [notification]);

  const handleConfirm = () => {
    setShowAlert(false);
    setShowAddDispenser(false);
    clear_notification();
  };

  const handleClose = () => {
    setShowAddDispenser(false);
    setSelectedStation('');
  };

  const onSearch = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  const onFilterStatus = (e) => {
    setStatusFilter(e.target.value);
  };

  const filteredStations = stations
    .filter((station) =>
      station.stationName.toLowerCase().includes(search) ||
      station.stationCode.toLowerCase().includes(search) ||
      station.stationAddress.toLowerCase().includes(search)
    )
    .filter((station) => (statusFilter ? station.status === statusFilter : true));

  const indexOfLastStation = currentPage * stationsPerPage;
  const indexOfFirstStation = indexOfLastStation - stationsPerPage;
  const currentStations = filteredStations.slice(indexOfFirstStation, indexOfLastStation);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const page = 'List Stations';

  return (
    <div className="niceadmin">
      <Header />
      <Sidebar page={page} />
      <Main>
        <SweetAlertWrapper
          show={showAlert}
          title="Notification"
          message={alertMessage.text}
          onConfirm={handleConfirm}
          confirmBtnText="OK"
          type={alertMessage.type}
        />

        {/* <AddDispenser show={showAddDispenser} handleClose={handleClose} stationCode={selectedStation} /> */}

        <div className="card">
          <div className="card-body">
            <h5 className="card-title">List Stations</h5>
            <Link className="btn btn-primary mb-3" to="/otogas/stations/add">
              Add Station
            </Link>

            <div className="row mb-3">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={search}
                  onChange={onSearch}
                />
              </div>
              <div className="col-md-4">
                <select
                  className="form-select"
                  value={statusFilter}
                  onChange={onFilterStatus}
                >
                  <option value="">All Statuses</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
            </div>

            <table className="table datatable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Station Name</th>
                  <th>Station Code</th>
                  <th>Station Address</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentStations.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No stations found
                    </td>
                  </tr>
                ) : (
                  currentStations.map((station, index) => (
                    <tr key={station.stationCode}>
                      <th scope="row">{indexOfFirstStation + index + 1}</th>
                      <td>{station.stationName}</td>
                      <td>{station.stationCode}</td>
                      <td>{station.stationAddress}</td>
                      <td>
                        <span
                          className={`badge ${station.status === 'Active' ? 'bg-success' : 'bg-danger'}`}
                        >
                          {station.status}
                        </span>
                      </td>
                      <td>
                        <div className="dropdown">
                          <button className="btn btn-light btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            Actions
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/otogas/stations/${station.stationCode}/view`}
                                state={{ station }}  // Pass the station data as state
                              >
                                <i className="bi bi-eye"></i> View
                              </Link>
                            </li>
                            {/* <li>
                              <a className="dropdown-item" href="#">
                                <i className="bi bi-gear"></i>{' '}
                                {station.status === 'Active' ? 'Deactivate' : 'Activate'}
                              </a>
                            </li> */}
                            {/* <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setSelectedStation(station.stationCode);
                                  setShowAddDispenser(true);
                                }}
                              >
                                <i className="bi bi-plus"></i> Add Dispenser
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            <Pagination
              itemsPerPage={stationsPerPage}
              totalItems={filteredStations.length}
              paginate={paginate}
              currentPage={currentPage}
              indexOfFirstItem={indexOfFirstStation}
              indexOfLastItem={indexOfLastStation}
            />
          </div>
        </div>
      </Main>
    </div>
  );
};

export default ListStations;
