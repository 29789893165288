import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import StationsContext from '../../context/otogas/stations/stationsContext';

const AddOrUpdateNozzleModal = ({ show, handleClose, dispenserCode, nozzleData }) => {
  const stationsContext = useContext(StationsContext);
  const { listDispensers, addStationNozzle } = stationsContext;

  const [formData, setFormData] = useState({
    nozzleName: '',
    dispenserCode: ''
  });

  const [isFormValid, setIsFormValid] = useState(false);

  // Pre-fill form data when nozzleData or dispenserCode is passed
  useEffect(() => {
    if (nozzleData) {
      setFormData({
        nozzleName: nozzleData.nozzleName || '',
        dispenserCode: nozzleData.dispenserCode || dispenserCode || ''
      });
    } else {
      setFormData({
        nozzleName: '',
        dispenserCode: dispenserCode || ''
      });
    }
  }, [nozzleData, dispenserCode]);

  // Fetch the list of dispensers on component mount
  useEffect(() => {
    listDispensers();
  }, [listDispensers]);

  // Validate form when formData changes
  useEffect(() => {
    const { nozzleName, dispenserCode } = formData;
    setIsFormValid(nozzleName.trim() !== '' && dispenserCode.trim() !== '');
  }, [formData]);

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const onSubmit = (e) => {
    e.preventDefault();
    if (isFormValid) {
      addStationNozzle(formData);
      handleClose(); // Close the modal after successful submission
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{nozzleData ? 'Update Nozzle' : 'Add Nozzle'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group controlId="formNozzleName">
            <Form.Label>Nozzle Name</Form.Label>
            <Form.Control
              type="text"
              name="nozzleName"
              value={formData.nozzleName}
              onChange={handleChange}
              required
              placeholder="Enter nozzle name"
            />
          </Form.Group>

          <Button variant="primary" type="submit" disabled={!isFormValid}>
            {nozzleData ? 'Update' : 'Save'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddOrUpdateNozzleModal;
