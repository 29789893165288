import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Header from "../../components/Layout/Header";
import Main from "../../components/Layout/Main";
import Sidebar from "../../components/Layout/Sidebar";
import StationsContext from "../../context/otogas/stations/stationsContext";
import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import { useNavigate } from "react-router-dom";

const AddStation = () => {
    const navigate = useNavigate();
    const stationsContext = useContext(StationsContext);
    const { addStation, updateStation, selected_station, notification, clear_notification } = stationsContext;

    const page = "Add Station";

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: {
            stationName: "",
            stationAddress: "",
            stationLongitude: "",
            stationLatitude: "",
        }
    });

    const handleConfirm = () => {
        setShowAlert(false);
        navigate('/otogas/stations');
        clear_notification();
    };

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });


    useEffect(() => {
        if (notification) {
            setAlertMessage({ text: notification.text, type: notification.type });
            setShowAlert(true);
        }
    }, [notification]);


    useEffect(() => {
        if (selected_station) {
            setValue("stationName", selected_station.stationName);
            setValue("stationAddress", selected_station.stationAddress);
            setValue("stationLongitude", selected_station.stationLongitude);
            setValue("stationLatitude", selected_station.stationLatitude);
        }
    }, [selected_station, setValue]);

    const onSubmit = (data) => {
        if (selected_station) {
            updateStation(data);
        } else {
            addStation(data);
        }
    };

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page={page} />
            <Main>
            <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type}
                />
                <div className="card" style={{ maxWidth: "600px", margin: "auto" }}>
                    <div className="card-body">
                        <h5 className="card-title">Add Station</h5>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label htmlFor="stationName">Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="stationName"
                                    {...register("stationName", { required: "Station name is required" })}
                                />
                                {errors.stationName && (
                                    <small className="text-danger">{errors.stationName.message}</small>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="stationAddress">Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="stationAddress"
                                    {...register("stationAddress", { required: "Station address is required" })}
                                />
                                {errors.stationAddress && (
                                    <small className="text-danger">{errors.stationAddress.message}</small>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="stationLongitude">Longitude</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="stationLongitude"
                                    {...register("stationLongitude", {
                                        required: "Longitude is required",
                                        pattern: {
                                            value: /^-?\d+(\.\d+)?$/,
                                            message: "Invalid longitude format"
                                        }
                                    })}
                                />
                                {errors.stationLongitude && (
                                    <small className="text-danger">{errors.stationLongitude.message}</small>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="stationLatitude">Latitude</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="stationLatitude"
                                    {...register("stationLatitude", {
                                        required: "Latitude is required",
                                        pattern: {
                                            value: /^-?\d+(\.\d+)?$/,
                                            message: "Invalid latitude format"
                                        }
                                    })}
                                />
                                {errors.stationLatitude && (
                                    <small className="text-danger">{errors.stationLatitude.message}</small>
                                )}
                            </div>
                            <button type="submit" className="btn btn-primary">
                                {selected_station ? 'Update Station' : 'Add Station'}
                            </button>
                        </form>
                    </div>
                </div>
            </Main>
        </div>
    );
};

export default AddStation;
