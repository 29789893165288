import React, { useContext, useEffect, useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import Header from "../../components/Layout/Header";
import Main from "../../components/Layout/Main";
import Sidebar from "../../components/Layout/Sidebar";
import CustomersContext from "../../context/otogas/customers/customersContext";
import StationsContext from "../../context/otogas/stations/stationsContext";
import Select from "react-select";
import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import { useNavigate } from "react-router-dom";
import debounce from 'lodash.debounce';



const AddVehicle = () => {
  const navigate = useNavigate();
  const {
    addVehicle,
    updateVehicle,
    selected_vehicle,
    customers,
    getCustomers,
    notification,
    clear_notification,
    products,
    tank_sizes,
    listTankSizes,
    listProducts } = useContext(CustomersContext);

  const { listStations, stations } = useContext(StationsContext);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);

  // Parameters for pagination and search
  const page = 1; // Example: static page number
  const page_size = 10; // Example: static page size
  const [customerPhone, setCustomerPhone] = useState('');

  useEffect(() => {
    listTankSizes();
  }, []);

  // Debounce function to fetch customers after a delay
  const debouncedFetchCustomers = debounce((inputValue) => {
    getCustomers(page, page_size, inputValue, customerPhone);
  }, 500); // 500ms debounce delay

  // Effect to update options once customers data changes
  useEffect(() => {
    console.log("called get customers")
    if (customers) {
      const options = customers.map((customer) => ({
        value: customer.customerCode,
        label: `${customer.customerName} (${customer.customerPhone})`,
      }));
      setCustomerOptions(options);
    }
  }, [customers]);

  // Handle selection of a customer
  const handleChange = (selectedOption) => {
    setSelectedCustomer(selectedOption);
    setValue('customerCode', selectedOption ? selectedOption.value : "");
    console.log('Selected customer:', selectedOption);
  };

  // Handle input changes for search
  const handleInputChange = (inputValue) => {
    debouncedFetchCustomers(inputValue); // Call the debounced function
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      customerCode: "",
      vehicleRegistrationNumber: "",
      vehicleMake: "",
      vehicleModel: "",
      tankCapacity: "",
      productCode: "",
      conversionStation: "",
      conversionDate: "",
      status: "0",
    },
  });

  useEffect(() => {
    // getCustomers();
    listProducts();
    listStations();
  }, []);

  useEffect(() => {
    if (notification) {
      setAlertMessage({ text: notification.text, type: notification.type });
      setShowAlert(true);
    }
  }, [notification]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });

  const handleConfirm = () => {
    setShowAlert(false);
    navigate("/otogas/vehicles");
    clear_notification();
  };

  const tankOptions = tank_sizes.map((tank) => ({
    value: tank.tankCapacity,
    label: tank.tankCapacity,
  }));



  const validateTankCapacity = (value) => {
    if (!value) return "Tank Capacity is required";
    if (isNaN(value) || value <= 0) return "Tank Capacity must be a positive number";
    return true;
  };




  // const customerOptions = customers.map((customer) => ({
  //   value: customer.customerCode,
  //   label: `${customer.customerName} - ${customer.customerPhone}`,
  // }));

  const stationsOptions = stations.map((station) => ({
    value: station.stationCode,
    label: station.stationName,
  }));

  const productCodeOptions = products.map((product) => ({
    value: product.productCode,
    label: product.productName,
  }));

  const onSubmit = (data) => {
    if (selected_vehicle) {
      updateVehicle(data);
    } else {
      addVehicle(data);
    }
  };

  return (
    <div className="niceadmin">
      <Header />
      <Sidebar page="Add Vehicle" />
      <Main>
        <SweetAlertWrapper
          show={showAlert}
          title="Notification"
          message={alertMessage.text}
          onConfirm={handleConfirm}
          confirmBtnText="OK"
          type={alertMessage.type}
        />
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-md-6 d-flex justify-content-between align-items-center">
                    <h5 className="card-title">Add Vehicle</h5>
                    <a href="/otogas/vehicles" className="btn btn-primary">
                      Back
                    </a>
                  </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Customer</label>
                        {/* <Select
                          options={customerOptions}
                          onChange={(selectedOption) =>
                            setValue("customerCode", selectedOption.value, { shouldValidate: true })
                          }
                          className={errors.customerCode ? "is-invalid" : ""}
                          value={customerOptions.find(
                            (option) => option.value === watch("customerCode")
                          )}
                        /> */}

                        <Select
                          options={customerOptions}
                          value={selectedCustomer}
                          onChange={handleChange}
                          onInputChange={handleInputChange}
                          placeholder="Search and select a customer"
                          isClearable
                        />
                        {errors.customerCode && (
                          <div className="text-danger mt-1">{errors.customerCode.message}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Vehicle Registration Number</label>
                        <input
                          type="text"
                          {...register("vehicleRegistrationNumber", {
                            required: "Vehicle Registration Number is required",
                          })}
                          className={`form-control ${errors.vehicleRegistrationNumber ? "is-invalid" : ""
                            }`}
                        />
                        {errors.vehicleRegistrationNumber && (
                          <div className="text-danger mt-1">
                            {errors.vehicleRegistrationNumber.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Vehicle Make</label>
                        <input
                          type="text"
                          {...register("vehicleMake", { required: "Vehicle Make is required" })}
                          className={`form-control ${errors.vehicleMake ? "is-invalid" : ""}`}
                        />
                        {errors.vehicleMake && (
                          <div className="text-danger mt-1">{errors.vehicleMake.message}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Vehicle Model</label>
                        <input
                          type="text"
                          {...register("vehicleModel", { required: "Vehicle Model is required" })}
                          className={`form-control ${errors.vehicleModel ? "is-invalid" : ""}`}
                        />
                        {errors.vehicleModel && (
                          <div className="text-danger mt-1">{errors.vehicleModel.message}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Tank Capacity</label>
                        <Controller
                          name="tankCapacity"
                          control={control}
                          rules={{ validate: validateTankCapacity }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={tankOptions}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption.value); // Set only the value
                              }}
                              className={errors.tankCapacity ? "is-invalid" : ""}
                              value={tankOptions.find(
                                (option) => option.value === watch("tankCapacity")
                              )}
                              placeholder="Select Tank Capacity"
                            />
                          )}
                        />
                        {errors.tankCapacity && (
                          <div className="text-danger mt-1">{errors.tankCapacity.message}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Product</label>
                        <select
                          {...register("productCode", { required: "Product Code is required" })}
                          className={`form-control ${errors.productCode ? "is-invalid" : ""}`}
                        >
                          <option value="">Select Product</option>
                          {productCodeOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {errors.productCode && (
                          <div className="text-danger mt-1">{errors.productCode.message}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Conversion Station</label>
                        <Controller
                          name="conversionStation"
                          control={control}
                          rules={{ required: "Conversion Station is required" }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={stationsOptions}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption.value); // Set only the value
                              }}
                              className={errors.conversionStation ? "is-invalid" : ""}
                              value={stationsOptions.find(
                                (option) => option.value === watch("conversionStation")
                              )}
                              placeholder="Select Conversion Station"
                            />
                          )}
                        />
                        {errors.conversionStation && (
                          <div className="text-danger mt-1">
                            {errors.conversionStation.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Conversion Date</label>
                        <input
                          type="date"
                          {...register("conversionDate", { required: "Conversion Date is required" })}
                          className={`form-control ${errors.conversionDate ? "is-invalid" : ""
                            }`}
                        />
                        {errors.conversionDate && (
                          <div className="text-danger mt-1">{errors.conversionDate.message}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Status</label>
                        <select
                          {...register("status", { required: "Status is required" })}
                          className={`form-control ${errors.status ? "is-invalid" : ""}`}
                        >
                          <option value="0">Inactive</option>
                          <option value="1">Active</option>
                        </select>
                        {errors.status && (
                          <div className="text-danger mt-1">{errors.status.message}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group mt-4">
                    <button type="submit" className="btn btn-primary" disabled={!isValid}>
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </Main>
    </div>
  );
};

export default AddVehicle;
