import React, { useState, useContext, useEffect } from 'react';
import { Dropdown, Table, Pagination, Form, Button, Row, Col, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import 'react-datepicker/dist/react-datepicker.css';

import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from '../../components/Layout/Main';
import RemoveSale from './removeSale';
import TransferSale from './transferSale';
import CustomersContext from '../../context/otogas/customers/customersContext';
import SweetAlertWrapper from '../../components/Layout/sweetAlert';

const ListSales = () => {
    const customersContext = useContext(CustomersContext);
    const { sales, salesTotalRecords, listSales, app_payment_types, listAppPaymentTypes, notification, clear_notification } = customersContext;


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items per page
    const [filters, setFilters] = useState({
        shiftNumber: '',
        dispenserName: '',
        nozzleName: '',
        paymentTypeName: '',
        startDate: '',
        endDate: '',
    });

    const [showDateRangeModal, setShowDateRangeModal] = useState(false);
    const [showRemoveSaleModal, setShowRemoveSaleModal] = useState(false);
    const [showTransferSaleModal, setShowTransferSaleModal] = useState(false);
    const [selectedSale, setSelectedSale] = useState(null); // Store entire sale object

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

    useEffect(() => {
        if (notification) {
          setAlertMessage({ text: notification.text, type: notification.type });
          setShowAlert(true);
        }
    }, [notification]);

    const handleConfirm = () => {
        setShowAlert(false);
        clear_notification();
    };

    // Fetch sales and payment types
    useEffect(() => {
        listSales(filters.shiftNumber, filters.dispenserName, filters.nozzleName, filters.paymentTypeName, filters.startDate, filters.endDate, currentPage, itemsPerPage);
        listAppPaymentTypes();
    }, [currentPage, filters, itemsPerPage]);

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
    };

    const handleDateRangeChange = (dates) => {
        const [start, end] = dates;
        setFilters({
            ...filters,
            startDate: start,
            endDate: end,
        });
    };

    const handleApplyFilters = () => {
        setCurrentPage(1); // Reset to first page
        listSales(filters.shiftNumber, filters.dispenserName, filters.nozzleName, filters.paymentTypeName, filters.startDate, filters.endDate, 1, itemsPerPage);
    };

    const handleRemoveSale = (sale) => {
        setSelectedSale(sale);
        setShowRemoveSaleModal(true);
    };

    const handleTransferSale = (sale) => {
        setSelectedSale(sale); // Store the entire sale object
        setShowTransferSaleModal(true);
    };

    const handleCloseModals = () => {
        setShowRemoveSaleModal(false);
        setShowTransferSaleModal(false);
        setSelectedSale(null);
    };

    const handleDownloadExcel = () => {
        const ws = XLSX.utils.json_to_sheet(sales);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sales");
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'sales.xlsx');
    };

    const totalPages = Math.ceil(salesTotalRecords / itemsPerPage);

    return (
        <>
            <Header />
            <Sidebar />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type}
                />
                <h2>List of Sales</h2>

                <Form>
                    <Row className="mb-3 align-items-center">
                        <Col md={3}>
                            <Form.Control
                                type="text"
                                placeholder="Shift Number"
                                name="shiftNumber"
                                value={filters.shiftNumber}
                                onChange={handleFilterChange}
                            />
                        </Col>
                        <Col md={3}>
                            <Form.Control
                                type="text"
                                placeholder="Dispenser Name"
                                name="dispenserName"
                                value={filters.dispenserName}
                                onChange={handleFilterChange}
                            />
                        </Col>
                        <Col md={3}>
                            <Form.Control
                                type="text"
                                placeholder="Nozzle Name"
                                name="nozzleName"
                                value={filters.nozzleName}
                                onChange={handleFilterChange}
                            />
                        </Col>
                        <Col md={3}>
                            <Form.Control
                                as="select"
                                name="paymentTypeName"
                                value={filters.paymentTypeName}
                                onChange={handleFilterChange}
                            >
                                <option value="">All Payment Types</option>
                                {(app_payment_types || []).map((type) => (
                                    <option key={type.paymentTypeId} value={type.paymentTypeName}>
                                        {type.paymentTypeName}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <Button variant="primary" onClick={() => setShowDateRangeModal(true)} block>
                                Select Date Range
                            </Button>
                        </Col>
                        <Col md={6}>
                            <Button variant="success" onClick={handleDownloadExcel} block>
                                Download Excel
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Shift Number</th>
                            <th>Station</th>
                            <th>Quantity</th>
                            <th>Vehicle Registration</th>
                            <th>Dispenser</th>
                            <th>Nozzle</th>
                            <th>Amount</th>
                            <th>Payment Type</th>
                            <th>Sale Id</th>
                            <th>Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sales.map((sale, index) => (
                            <tr key={sale.id}>
                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                <td>{sale.shiftNumber}</td>
                                <td>{sale.stationName}</td>
                                <td>{sale.quantity}</td>
                                <td>{sale.vehicleRegistrationNumber}</td>
                                <td>{sale.dispenserName}</td>
                                <td>{sale.nozzleName}</td>
                                <td>{sale.amount}</td>
                                <td>{sale.paymentTypeName}</td>
                                <td>{sale.saleId}</td>
                                <td>{new Date(sale.dateCreated).toLocaleString()}</td>
                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                            Actions
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleTransferSale(sale)}>
                                                Transfer Sale
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleRemoveSale(sale)}>
                                                Reverse Sale
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {totalPages > 1 && (
                    <Pagination className="justify-content-center">
                        <Pagination.Prev
                            onClick={() => setCurrentPage(currentPage - 1)}
                            disabled={currentPage === 1}
                        />
                        {Array.from({ length: totalPages }, (_, i) => (
                            <Pagination.Item
                                key={i + 1}
                                active={i + 1 === currentPage}
                                onClick={() => setCurrentPage(i + 1)}
                            >
                                {i + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        />
                    </Pagination>
                )}
            </Main>

            {/* Remove Sale Modal */}
            <RemoveSale
                show={showRemoveSaleModal}
                handleClose={handleCloseModals}
                saleId={selectedSale?.saleId}
            />

            {/* Transfer Sale Modal */}
            <TransferSale
                show={showTransferSaleModal}
                handleClose={handleCloseModals}
                sale={selectedSale} // Pass the entire sale object
            />

            {/* Date Range Modal */}
            <Modal show={showDateRangeModal} onHide={() => setShowDateRangeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Date Range</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DatePicker
                        selected={filters.startDate}
                        onChange={handleDateRangeChange}
                        startDate={filters.startDate}
                        endDate={filters.endDate}
                        selectsRange
                        className="form-control"
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Select Date Range"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDateRangeModal(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setShowDateRangeModal(false);
                            handleApplyFilters();
                        }}
                    >
                        Apply Date Range
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ListSales;

// {
//     "stationName": "Otogas Kayole",
//     "nozzleCode": "N04",
//     "quantity": 0.01,
//     "vehicleRegistrationNumber": "KDJ095B",
//     "dispenserName": "D1",
//     "nozzleName": "N1",
//     "paymentTypeName": "Mpesa",
//     "saleId": "NILCE155636322ZTUSQTTEB8",
//     "dateCreated": "2024-09-15T15:56:36.5873949",
//     "shiftNumber": "NOSIL151726182",
//     "dispenserCode": "D06",
//     "amount": 1.15
// }