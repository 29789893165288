import React, { useEffect, useContext, useState } from "react";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import { Modal, Button, Form } from "react-bootstrap";
import CustomersContext from "../../context/otogas/customers/customersContext";
import SweetAlertWrapper from '../../components/Layout/sweetAlert';

const ListCustomerBalances = () => {
  const customersContext = useContext(CustomersContext);
  const { customer_balances,notification, clear_notification, listCustomerBalances, topUpCustomerBalance } = customersContext;

  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [showModal, setShowModal] = useState(false);
  const [selectedCustomerBalance, setSelectedCustomerBalance] = useState(null);
  const [topUpAmount, setTopUpAmount] = useState("");
  const [paymentReference, setPaymentReference] = useState("");


  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });


  useEffect(() => {
    listCustomerBalances();
  }, [listCustomerBalances]);

  useEffect(() => {
    if (notification) {
      console.log(notification);

      setAlertMessage({ text: notification.text, type: notification.type });
      setShowAlert(true);
    }
  }, [notification]);
  const handleConfirm = () => {
    setShowAlert(false);
    clear_notification();
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleTopUp = (customer_balance) => {
    setSelectedCustomerBalance(customer_balance);
    setShowModal(true); // Show the modal
  };

  const handleTopUpSubmit = async () => {
    if (topUpAmount && parseFloat(topUpAmount) > 0 && paymentReference) {
      try {
        console.log(selectedCustomerBalance)
        await topUpCustomerBalance({
            vehicleCode: selectedCustomerBalance.vehicleCode,
            amount: parseFloat(topUpAmount),
            paymentReference,
        });
        setShowModal(false); // Hide the modal after successful top-up
      } catch (error) {
        console.error("Error topping up balance", error);
      }
    }
  };

  const filteredBalances = customer_balances.filter((balance) => {
    const matchesSearch = balance.customerName.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = filterStatus === "all" || balance.status === filterStatus;
    return matchesSearch && matchesStatus;
  });

  return (
    <>
      <Header />
      <Sidebar />
      <Main>
      <SweetAlertWrapper
          show={showAlert}
          title="Notification"
          message={alertMessage.text}
          onConfirm={handleConfirm}
          confirmBtnText="OK"
          type={alertMessage.type}
        />

        <h2>Customer Balances</h2>
        <div className="row mb-3">
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              placeholder="Search by customer name"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className="col-md-4">
            <select className="form-select" value={filterStatus} onChange={handleFilterChange}>
              <option value="all">All Statuses</option>
              <option value="due">Due</option>
              <option value="overdue">Overdue</option>
              <option value="paid">Paid</option>
            </select>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Balance Amount</th>
              <th>Registration Number</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredBalances.map((balance) => (
              <tr key={balance.customerId}>
                <td>{balance.customerName}</td>
                <td>{balance.balance}</td>
                <td>{balance.registrationNumber}</td>
                <td>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Actions
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li>
                        <button className="dropdown-item" onClick={() => handleTopUp(balance)}>
                          Top-up Balance
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Modal for Top-up */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Top-up Balance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Top-up Amount</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter amount"
                  value={topUpAmount}
                  onChange={(e) => setTopUpAmount(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Payment Reference</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter payment reference"
                  value={paymentReference}
                  onChange={(e) => setPaymentReference(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleTopUpSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </Main>
    </>
  );
};

export default ListCustomerBalances;
