import React, { useEffect, useContext, useState, useRef } from "react";
import { Table, Button, Row, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import CustomersContext from "../../context/otogas/customers/customersContext";
import { useParams } from "react-router-dom";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import "react-datepicker/dist/react-datepicker.css";

const TransactionPage = () => {
  const regNo = useParams().vehicleCode;
  console.log(regNo);
  const customersContext = useContext(CustomersContext);
  const { vehicle_summary, getVehicleSummary } = customersContext;

  // Set the initial date range to be one month before today, but allow any range afterwards
  const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  const [endDate, setEndDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef(null);

  // Fetch vehicle summary when dates or regNo change
  useEffect(() => {
    if (startDate && endDate) {
      const start = startDate.toISOString().split("T")[0]; // Using ISO format for API request
      const end = endDate.toISOString().split("T")[0];

      console.log(regNo, start, end);

      getVehicleSummary(regNo, start, end);
    }
  }, [regNo, startDate, endDate]);

  // Handle date range selection
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end || start); // If end date is not selected, use start date
    
    // Close DatePicker when both start and end dates are selected
    if (end) {
      setShowDatePicker(false);
    }
  };

  // Close DatePicker when clicking outside
  const handleClickOutside = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setShowDatePicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Header />
      <div className="niceadmin">
        <Sidebar />
        <Main>
          <div className="card shadow-sm">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="card-title">{regNo} Transactions Summary</h2>
              </div>

              <Row className="mb-4">
                <Col md={6}>
                  <Form.Group className="d-flex align-items-center">
                    <Form.Label className="me-3 mb-0">Date Range:</Form.Label>
                    <Button
                      variant="outline-primary"
                      onClick={() => setShowDatePicker(!showDatePicker)}
                      className="me-2"
                    >
                      {startDate && endDate
                        ? `${startDate.toLocaleDateString("en-GB")} - ${endDate.toLocaleDateString("en-GB")}`
                        : "Select Date Range"}
                    </Button>
                  </Form.Group>
                  {showDatePicker && (
                    <div className="position-relative" ref={datePickerRef}>
                      <div className="position-absolute zindex-dropdown">
                        <DatePicker
                          selected={startDate}
                          onChange={handleDateChange}
                          startDate={startDate}
                          endDate={endDate}
                          selectsRange
                          inline
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                  )}
                </Col>
              </Row>

              <Table striped bordered hover responsive>
                <thead className="table-light">
                  <tr>
                    <th>Description</th>
                    <th>Credit</th>
                    <th>Debit</th>
                    <th>Running Balance</th>
                    <th>Date Created</th>
                  </tr>
                </thead>
                <tbody>
                  {vehicle_summary.length > 0 ? (
                    vehicle_summary.map((transaction) => (
                      <tr key={transaction.id}>
                        <td>{transaction.description}</td>
                        <td>{transaction.credit}</td>
                        <td>{transaction.debit}</td>
                        <td>{transaction.runningBalance}</td>
                        <td>{new Date(transaction.dateCreated).toLocaleString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No transactions found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Main>
      </div>
    </>
  );
};

export default TransactionPage;
