// Importing Libraries
import React, { useEffect, useContext } from 'react';
import { Route, Routes } from 'react-router-dom'
// Importing Pages

import PageDashboard from './pages/PageDashboard';
import PageLogin from './pages/PageLogin';
import RegisterUsers from './pages/Authentication/RegisterUsers';


import AddOrders from './pages/Orders/AddOrders';

import PageError404 from './pages/PageErorr404';
import ListTrucks from './pages/Trucks/ListTrucks';
import ListDeliveryPoints from './pages/DeliveryPoints/ListDeliveryPoints';
import AlertState from './context/alerts/alertState';
import OrderState from './context/orders/ordersState';
import UserState from './context/users/userState';
import CustomerState from './context/otogas/customers/customerState';
import StationsState from './context/otogas/stations/stationsState';
import OtogasSales from './components/otogas/sales';
import Users from './components/admin/users';
import BulkOrders from './components/bulk/orders';
import AuthContext from './context/auth/authContext';


import ProtectedRoute from './components/protectedRoute';
import ListCustomers from './pages/customers/ListCustomers';
import AddCustomers from './pages/customers/addCutomers';
import ListVehicles from './pages/customers/ListVehicles';
import AddVehicle from './pages/customers/addVehicle';
import ListStations from './pages/stations/ListStations';
import Station from './pages/stations/station';
import AddSale from './pages/sales/addSale';
import AddStation from './pages/stations/addStation';
import ListTillNumbers from './pages/tills/listTills';
import UserProfile from './pages/Authentication/profile';
import AddTillForm from './pages/tills/addTill';
import ListSales from './pages/sales/ListSales';
import SettingsPage from './pages/settings/settings';
import Shiftvariances from './pages/stock/variances';
import ListCustomerBalances from './pages/customers/customerBalances';
import VehicleSummary from './pages/customers/vehicleSummary';
import CustomerSummaryPage from './pages/customers/customer';
import UnauthorizedPage from './pages/PageError403';
import ListRoles from './components/admin/roles';


let token = localStorage.getItem("token");

function App() {
  const authContext = useContext(AuthContext);
  const { loadUser } = authContext || {}; // Using optional chaining
  

  useEffect(() => {
    if (token) {
        loadUser()
    }
  }, [token]);

  return (
    <UserState>
      <StationsState>
        <CustomerState>
          <OrderState>
            <AlertState>
              <React.Fragment>
                <Routes>
                  {/*  Protected Route */}

                  <Route path="*" element={<ProtectedRoute element={<PageDashboard />} />} />
                  <Route path="/addorders" element={<AddOrders />} />

                  {/* Otogas Routes */}
                  <Route path="/otogas/sales"  element={<ProtectedRoute  element={<ListSales />} />} />
                  <Route path="/otogas/sales/add" element={<ProtectedRoute  element={<OtogasSales />} />} />
                  <Route path="/otogas/users" element={<ProtectedRoute  element={<Users />} />} />
                  <Route path="/otogas/customers" element={<ProtectedRoute  element={<ListCustomers />} />} />
                  <Route path="/otogas/customers/:customerCode" element={<ProtectedRoute  element={<CustomerSummaryPage />} />} />
                  <Route path="/otogas/customers/balances" element={<ProtectedRoute  element={<ListCustomerBalances />} />} />
                  <Route path="/otogas/customers/add" element={<ProtectedRoute  element={< AddCustomers />} />} />
                  <Route path="/otogas/vehicles" element={<ProtectedRoute  element={<ListVehicles />} />} />
                  <Route path="/otogas/vehicles/add" element={<ProtectedRoute  element={<AddVehicle />} />} />
                  <Route path="/otogas/vehicles/:vehicleCode" element={<ProtectedRoute  element={<VehicleSummary />} />} />
                  <Route path="/otogas/pricing" element={<ProtectedRoute  element={<OtogasSales />} />} />
                  <Route path="/otogas/tills" element={<ProtectedRoute  element={<ListTillNumbers />} />} />
                  <Route path="/otogas/settings" element={<ProtectedRoute  element={< SettingsPage />} />} />
                  <Route path="/otogas/variances" element={<ProtectedRoute  element={< Shiftvariances />} />} />


                  {/* Bulk Routes */}
                  <Route path="/otogas/bulk-orders" element={<ProtectedRoute element={<BulkOrders/>} />} />
                  <Route path="/otogas/trips" element={<ProtectedRoute element={<BulkOrders/>} />} />
                  <Route path="/otogas/drivers" element={<ProtectedRoute element={<BulkOrders/>} />} />

                  {/* Auth Routes */}
                  <Route path="/login" element={<PageLogin />} />

                  {/* payments */}
                  <Route path="/otogas/payments"element={<ProtectedRoute element={<ListTillNumbers />} /> } />

                  {/* stations */}
                  <Route path="/otogas/stations" element={<ListStations />} />
                  <Route path="/otogas/stations/add" element={<AddStation />} />
                  <Route path="/otogas/stations/:stationCode/view" element={<Station />} />
                  {/* Users Routes */}
                  {/* <Route path="/users" element={<Users />} /> */}
                  <Route path="/users/edit/:id" element={<RegisterUsers />} />
                  <Route path="/users/create" element={<RegisterUsers />} />

                  <Route path="/users/roles" element={<ListRoles />} />
                  <Route path="/users/:id/profile" element={<Users />} />
                  <Route path="/users/profile" element={<ProtectedRoute element={<UserProfile />} />} />


                  {/* Sales Routes */}
                  <Route path="/otogas/add-sale" element={<AddSale />} />

                  <Route path="/trucks" element={<ListTrucks />} />
                  <Route path="/delivery_points" element={<ListDeliveryPoints />} />

                  <Route path="*" element={<PageError404 />} />
                  <Route path="/404" element={<PageError404 />} />
                  <Route path="/forbidden" element={<UnauthorizedPage />} />
                  {/* Protected Route */}

                  {/* utils */}
                </Routes>
              </React.Fragment>
            </AlertState>
          </OrderState>
        </CustomerState>
      </StationsState>
    </UserState>
  );
}

export default App;
