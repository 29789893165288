import React, { useEffect, useState, useContext } from "react";
import { Modal, Button, Form } from 'react-bootstrap';

import StationsContext from "../../context/otogas/stations/stationsContext";

const AddDispenser = ({ show, handleClose, stationCode }) => {
    const stationsContext = useContext(StationsContext);
    const { addStationDispenser } = stationsContext;

    const [dispenser, setDispenser] = useState({
        dispenserName: "",
        stationCode: stationCode || ""
    });

    useEffect(() => {
        setDispenser(prevDispenser => ({
            ...prevDispenser,
            stationCode: stationCode
        }));
    }, [stationCode]);

    const onChange = (e) => {
        const { name, value } = e.target;
        setDispenser({
            ...dispenser,
            [name]: value
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        addStationDispenser(dispenser);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Dispenser</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formNozzleName">
                        <Form.Label>Dispenser Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Dispenser Name"
                            name="dispenserName"
                            value={dispenser.dispenserName}
                            onChange={onChange}
                        />
                    </Form.Group>

                    {/* add till number, sapCoee */}

                    <Form.Group controlId="formTillNumber">
                        <Form.Label>Till Number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Till Number"
                            name="tillNumber"
                            value={dispenser.tillNumber}
                            onChange={onChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="formSapCode">
                        <Form.Label>SAP Code</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter SAP Code"
                            name="storageLocation"
                            value={dispenser.storageLocation}
                            onChange={onChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={onSubmit}>
                    Add Dispenser
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddDispenser;
