import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertContext from '../context/alerts/alertContext';
import AuthContext from '../context/auth/authContext';
import Alerts from '../components/Layout/Alerts';
import logo from '../img/main.png';

const Login = () => {
  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const authContext = useContext(AuthContext);
  const { login, error, clearErrors, isAuthenticated } = authContext;

  const [user, setUser] = useState({
    username: "",
    password: ""
  });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { username, password } = user;

  useEffect(() => {
    if (isAuthenticated) {
      setAlert('Login Successful', 'success');
      navigate('/');
    }
    if (error) {
      setAlert(error, 'danger');
      clearErrors();
    }
  }, [isAuthenticated, error, navigate, clearErrors, setAlert]);

  const onChange = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    if (username === "" || password === "") {
      setAlert("Please fill in all fields", "danger");
    } else {
      login({ userName: username, password });
    }
  };

  return (
    <div className="login-page" >
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                {/* Card */}
                <div className="card shadow-lg rounded mb-3" style={{ borderRadius: '10px', overflow: 'hidden' }}>
                  <div className="card-body p-4">

                   
                    

                    <form className="row g-3 needs-validation" onSubmit={onSubmit} noValidate>
                      <div className="col-12">
                        <Alerts />
                        <label htmlFor="username" className="form-label">Username</label>
                        <div className="input-group">
                          <input
                            type="text"
                            onChange={onChange}
                            name="username"
                            className="form-control shadow-sm"
                            id="username"
                            autoComplete="on"
                            placeholder="Enter username"
                            value={username}
                            required
                          />
                          <div className="invalid-feedback">Username is required.</div>
                        </div>
                      </div>

                      <div className="col-12">
                        <label htmlFor="password" className="form-label">Password</label>
                        <div className="input-group">
                          <input
                            type={showPassword ? "text" : "password"}
                            onChange={onChange}
                            name="password"
                            className="form-control shadow-sm"
                            id="password"
                            placeholder="Enter password"
                            value={password}
                            required
                          />
                          <i
    className={`bi bi-eye${showPassword ? "-slash" : ""} password-toggle-icon`}
    onClick={togglePasswordVisibility}
    style={{
      position: "absolute",
      top: "50%",
      right: "10px",
      transform: "translateY(-50%)",
      cursor: "pointer"
    }}
  ></i>

                          <div className="invalid-feedback">Password is required.</div>
                        </div>
                      </div>

                      <div className="col-12 d-flex justify-content-between align-items-center">
                        <a href="/forgot-password" className="small text-decoration-none text-muted">Forgot password?</a>
                      </div>

                      <div className="col-12">
                        <button
                          className="btn btn-primary w-100 shadow"
                          type="submit"
                        
                        >
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Login;
