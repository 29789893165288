import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation to access state
import { Table, Container, Row, Col, Spinner } from 'react-bootstrap';
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from "../../components/Layout/Main";
import CustomersContext from "../../context/otogas/customers/customersContext";

const CustomerSummaryPage = () => {
  const location = useLocation();  // Get location to access state
  const customer = location.state;  // Retrieve customer from state
  const customerContext = useContext(CustomersContext);
  const { fetchVehicles, vehicles } = customerContext;

  // Fetch vehicles based on customerCode
  useEffect(() => {
    if (customer && customer.customerCode) {
      console.log("we called fetch vehicles")
      // fetchVehicles(customer.customerCode);  // Fetch vehicles if customer exists

      fetchVehicles(
        1,10,customer.customerName,'','', true, 
      )
    }
  }, [customer]);

  // Filter vehicles by customerCode

  if (!customer) {
    return (
      <div>
        <Header />
        <Sidebar page="Add Vehicle" />
        <Main>
          <Container>
            <Row>
              <Col className="text-center">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
                <p>Loading customer data...</p>
              </Col>
            </Row>
          </Container>
        </Main>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <Sidebar page="Add Vehicle" />
      <Main>
        <Row>
          <Col>
            <h2>Customer Summary</h2>
            <p><strong>Name:</strong> {customer.customerName}</p>
            <p><strong>Phone:</strong> {customer.customerPhone}</p>
            <p><strong>Email:</strong> {customer.customerEmail}</p>
            <p><strong>KRA Pin:</strong> {customer.kraPin}</p>
            <p><strong>Identification Number:</strong> {customer.identificationNumber}</p>
            <p><strong>Date Created:</strong> {new Date(customer.dateCreated).toLocaleDateString()}</p>

            <h3>Customer Vehicles</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Vehicle Code</th>
                  <th>Registration Number</th>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Tank Capacity</th>
                  <th>Status</th>
                  <th>Date Created</th>
                </tr>
              </thead>
              <tbody>
                {vehicles.length > 0 ? (
                  vehicles.map((vehicle) => (
                    <tr key={vehicle.vehicleCode}>
                      <td>{vehicle.vehicleCode}</td>
                      <td>{vehicle.vehicleRegistrationNumber || 'N/A'}</td>
                      <td>{vehicle.vehicleMake}</td>
                      <td>{vehicle.vehicleModel}</td>
                      <td>{vehicle.tankCapacity}</td>
                      <td>{vehicle.isActive ? 'Active' : 'Inactive'}</td>
                      <td>{new Date(vehicle.dateCreated).toLocaleDateString()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">No vehicles found for this customer.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Main>
    </div>
  );
};

export default CustomerSummaryPage;
